@import '../../styles';

.back{
  color: $blue;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 20px;
  top: 30px;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: $media-query-desktop) {
    top: 10px;
  }
  svg{
    margin-right: 6px;
  }
}
