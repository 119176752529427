@import '../../styles';

.dropzone{
  width: 95px;
  height: 95px;
  border-radius: 50%;
  padding: 10px;
  background-color: $white;
  color: $grey;
  font-size: 12px;
  line-height: 17px;
  transition: all ease 0.4s;
  position: relative;
  margin: 20px auto;
  display: block;
  .container-dropzone{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  @media (max-width: $media-query-mobile-xxs) {
    width: 75px;
    height: 75px;
    margin: 0 auto 10px;
    font-size: 10px;
  }

  &.dropped{
    background-color: $blue;
    color: $white;
  }

  .zone {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    @media (max-width: $media-query-mobile-xxs) {
      width: 90px;
      height: 90px;
    }
  }
}

