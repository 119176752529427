@import '../../styles';

.bubble{
  width: 81px;
  height: 81px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow;
  opacity: 1;
  transition: all ease 0.4s;
  &.hidden{
    opacity: 0;
  }
  @media (max-width: $media-query-mobile-xxs) {
    width: 60px;
    height: 60px;
    svg{
      width: 30px;
      height: 30px;
    }
  }
}

.boxes{

  .react-beautiful-dnd-copy ~ li {
    transform: none !important;
  }

  .react-beautiful-dnd-copy {
    opacity: 0;
  }

  .react-beautiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
    //display: none !important;
  }

}
