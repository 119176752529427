@import '../../styles';

h1{
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  margin: 14px 0;

  @media (max-width: $media-query-tablet) {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 5px;
  }
  span{
    font-weight: 700;
    font-family: $font-family-parisine-bold;
  }
}

.description-title{
  margin: 0 0 8px;
  font-weight: 700;
  font-family: $font-family-parisine-bold;
  @media (max-width: $media-query-tablet) {
    font-size: 12px;
  }
}
