@import '../../styles';
/*.bus-wrapper{
  h1{
    @media (max-width: $media-query-mobile-xxs) {
      font-size: 20px;
      line-height: 27px;
      margin: 14px 0;
    }
  }
}*/
.container-buses{
  max-width: 560px;
  margin: 20px auto;
  @media (max-width: $media-query-mobile) {
    min-height: 50vh;
    min-height: calc(var(--vh, 1vh) * 50);
  }
  .column-bus{
    max-height: 50vh;
    max-height: calc(var(--vh, 1vh) * 50);
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    .bus{
      max-width: calc((100%/3) - 2px);
      width: calc((100%/3) - 2px);
      @media (max-width: $media-query-desktop) {
        max-width: calc(50% - 2px);
        width: calc(50% - 2px);
      }
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-parisine-bold;
      color: $black;
      background-color: $light-grey;
      margin: 1px;
      font-size: 22px;
      cursor: pointer;
      @media (max-width: $media-query-mobile) {
        font-size: 18px;
      }
    }
  }
}
