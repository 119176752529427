@import '../../styles';

.line{
  @media (orientation: portrait) {
    overflow: hidden;
  }
  h1{
    @media (max-width: $media-query-tablet) {
      margin: 14px 0 5px 0;
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: $media-query-mobile-xxs) {
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      min-height: 52px;
    }
  }
  .validateButton{
    bottom: 0;
    @media (max-width: $media-query-mobile-xxs) {
      bottom: 45px;
      max-width: 120px;
    }
  }

  .container-color{
    @media (max-width: $media-query-mobile) {
      min-height: unset;
    }
  }

  .container{
    padding: 0;
    @media (max-width: $media-query-mobile-xxs) {
      padding: 20px 0;
    }
  }
  .page-wrapper{
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    @media (max-width: $media-query-desktop) {
      height: auto;
    }
  }

  canvas {
    background-color: $white;
  }
}
