@import './styles';

.mode.page-wrapper{
 // background: green;
}

.App {
  font-family: $font-family-parisine-regular;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $light-grey;
  position: relative;
  @media (max-width: $media-query-desktop) {
    overflow-y: scroll;
  }
  @media (max-width: $media-query-mobile) {
    height: auto;
  }
}


.mode, .bus {
  .App {
    overflow-y: scroll;
    height: auto;
    @media (max-width: $media-query-desktop) {
      overflow-y: scroll;
    }

    .page-wrapper {
      height: 100%;
      min-height: calc((var(--vh, 1vh) * 100) - 90px);
      @media (max-width: $media-query-desktop) {
        height: calc((var(--vh, 1vh) * 100) - 60px);
        @media (orientation: landscape) {
          height: calc((var(--vh, 1vh) * 100) - 60px);
        }
      }
      max-height: 100%;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mode-wrapper, .container-buses {
        flex: 1;
      }
      .mode-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .container {
      padding-bottom: 0;
      height: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      @media (max-width: $media-query-desktop) and (min-height: 600px) {
        overflow: unset;
      }
      @media (orientation: landscape) {
        overflow: unset;
      }
      @media (max-width: $media-query-mobile-xxs){
        max-height: 100vh;
      }
    }

    .container-color {
      height: calc(var(--vh, 1vh) * 100);
      min-height: 494px;
    }
  }
}

.mode, .bus {
  &.gif {
    overflow: hidden;
    @media (orientation: landscape) {
      overflow-y: scroll;
    }

    .container {
      overflow: hidden;
    }

    .App {
      overflow: hidden;
    }

    .container-color {
      min-height: unset;
      height: 100%;
    }

  }

}

.mode {
  .App{

    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      .container-color {
        min-height: 650px;
      }
    }
    h1{
      margin-bottom: 5px;
      line-height: 24px;
      margin-top: 5px;
    }
    .description-title{
      margin-bottom: 0;
      @media (max-width: $media-query-desktop) {
        margin-bottom: 8px;
      }
    }
    @media (max-width: $media-query-mobile-xxs){
      .container-color{
        min-height: unset;
        h1{
          font-size: 15px;
        }
      }
    }
  }

  &.gif {
    .page-wrapper.mode, .page-wrapper.bus-wrapper {
      min-height: unset;
      height: calc((var(--vh, 1vh) * 100) - 20px);
    }

    @media (max-width: $media-query-mobile-xxs){
      .container{
        height: 100vh;
      }
    }

    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      .page-wrapper.mode, .page-wrapper.bus-wrapper {
        min-height: 650px;
      }
    }
  }
}
.bus {
  @media (max-width: $media-query-mobile-xxs){
    overflow: hidden;
  }
  .App{

    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      .container-color {
        min-height: 566px;
      }
    }
  }

  &.gif {
    .page-wrapper.mode, .page-wrapper.bus-wrapper {
      min-height: unset;
      height: calc(100vh - 20px);
      height: calc((var(--vh, 1vh) * 100) - 20px);
    }

    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      .page-wrapper.mode, .page-wrapper.bus-wrapper {
        min-height: 586px;
      }
    }
  }
}

.page-wrapper {
  padding-top: 100px;
  width: 100%;
  @media (max-width: $media-query-desktop) {
    padding-top: 56px;
  }

  &.mode {
    padding-top: 60px;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    padding-bottom: 130px;
    height: 100%;
    @media (max-width: $media-query-desktop) {
      padding-top: 30px;
    }
    @media (max-width: $media-query-mobile) {
      height: 100%;
    }
  }

  &.bus-wrapper {
    min-height: calc(100vh - 130px);
    min-height: calc((var(--vh, 1vh) * 100) - 130px);
    padding-top: 60px;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    padding-bottom: 130px;
    height: 100%;
    @media (max-width: $media-query-desktop) {
      padding-top: 30px;
    }
    @media (max-width: $media-query-mobile) {
      height: 100%;
    }
  }
}

/*
.bus {
  .container-color {
    height: 100vh;
    @media (max-width: $media-query-desktop) {
      overflow: hidden;
    }
  }

  .App {
    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      min-height: 600px;
      .container-color {
        min-height: 600px;
      }
    }
  }

  .container-color {
    min-height: unset;

    .container {
      height: 100%;
      @media (max-width: $media-query-mobile-xxs) {
        height: auto;
      }
    }
  }
}
*/

.line {
  @media (max-width: $media-query-desktop) and (orientation: landscape) {
    .App {
      min-height: 600px;
      height: auto;
    }
  }
  @media (max-width: $media-query-mobile) {
      .App{
        height: calc(var(--vh, 1vh) * 100);
      }
  }
}


.container-color {
  max-width: 812px;
  width: 100%;
  height: 100%;
  background-color: $white;
  overflow: hidden;
  @media (max-width: $media-query-desktop) {
    max-width: 375px;
    width: 375px;
  }
  @media (max-width: $media-query-mobile) {
    max-width: 100%;
    width: 100%;
    padding: 0;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .container {
    margin: auto;
    background-color: $white;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
    @media (max-width: $media-query-mobile) {
      max-width: 100%;
      height: auto;
      max-height: unset;
    }
  }
}


ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
