$blue: #0E44AB;
$blue-green: #5CD6BF;
$pink: #FF4498;
$orange: #FFB400;
$yellow: #F9EB0D;
$violet: #C929F9;
$dark-orange: #FF6C00;
$light-green: #64E24A;
$light-blue: #00B2FF;
$black: #000;
$white: #FFF;
$grey: #979797;
$light-grey: #F5F5F5;
$red: #FF0000;
@font-face {
    font-family: 'Parisine-Regular';
    src: url('assets/fonts/Parisine/ParisineRegular/ParisineRegular.woff') format('woff'),
    url('assets/fonts/Parisine/ParisineRegular/ParisineRegular.woff2') format('woff2'),
    url('assets/fonts/Parisine/ParisineRegular/ParisineRegular.otf') format('opentruetype'),
    url('assets/fonts/Parisine/ParisineRegular/ParisineRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Parisine-Bold';
    src: url('assets/fonts/Parisine/ParisineBold/ParisineBold.woff') format('woff'),
    url('assets/fonts/Parisine/ParisineBold/ParisineBold.woff2') format('woff2'),
    url('assets/fonts/Parisine/ParisineBold/ParisineBold.otf') format('opentruetype'),
    url('assets/fonts/Parisine/ParisineBold/ParisineBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$font-family-parisine-regular: 'Parisine-Regular';
$font-family-parisine-bold: 'Parisine-Bold';

$media-query-desktop-up : 1200px;
$media-query-desktop : 812px;
$media-query-tablet : 767px;
$media-query-mobile : 425px;
$media-query-mobile-xxs : 374px;
