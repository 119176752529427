@import '../../styles';
.mode, .bus {
  .hidden-button{
    width: 100%;
    height: 120px;
    min-height: 120px;
    overflow: hidden;
    @media (max-width: $media-query-mobile-xxs) {
      height: calc(140px * 64/100);
      min-height: calc(140px * 64/100);
    }
  }

  .validateButton{
      position: static;
      bottom: unset;
      left: unset;
      right: unset;
      margin: auto;
      transform: translateX(0) translateY(0);
    }
}

.validateButton {
  position: absolute;
  z-index: 100;
  right: 50%;
  left: 50%;
  bottom: -45px;
  transform: translateX(-50%) translateY(50%);
  font-size: 0;
  overflow: hidden;
  border-radius: 50%;
  pointer-events: none;
  width: 240px;
  height: 240px;
  @media (max-width: $media-query-mobile-xxs) {
    width: 64%;
    height: auto;
  }
  &:before{
    content: "";
    position: relative;
    display: block;
    padding-top: 100%;
  }
  button{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $blue;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 20px;
    color: $white;
    pointer-events: auto;
    border: none;
    box-shadow: none;
    transition: all ease 0.5s;
    cursor: pointer;
    &:disabled{
      span{
        opacity: 0.1;
      }
      pointer-events: none;
    }
    span{
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);
      font-family: $font-family-parisine-bold;
      font-size: 28px;
      line-height: 32px;
      transition: all ease 0.5s;
    }
  }
}
