@import '../../styles';

.progress-container{
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  z-index: 105;
  max-width: 400px;
  transform: translateX(-50%);
  padding: 0 20px;
  margin: 70px auto 20px;
  @media (max-width: $media-query-desktop) {
    top: 8px;
    max-width: unset;
    left: 0;
    transform: none;
    margin-top: 35px;
  }
  .progress {
    height: 6px;
    background-color: $blue-green;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: $media-query-tablet) {
      height: 4px;
    }
    .checkpoint{
      width: 16px;
      height: 16px;
      display: block;
      background-color: $white;
      border: 2px solid $blue-green;
      border-radius: 50%;
      transform: translateY(-7.5px);
      @media (max-width: $media-query-tablet) {
        width: 13px;
        height: 13px;
      }
    }
    .active-point{
      position: absolute;
      background-color: $white;
      border-color: $black;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease 0.5s;
      .round{
        width: 6px;
        height: 6px;
        background-color: $black;
        border-radius: 50%;
      }
    }
  }
}
