@import '../../styles';

.container-checkbox {
  display: flex;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
  margin: 4px auto 20px;
  max-width: 280px;


  &.error{
    .text{
      color: $red;
    }
  }

  .text{
    font-size: 12px;
    line-height: 14px;
    color: $white;
    text-align: left;
    margin-left: 8px;
    font-family: $font-family-parisine-regular;
    a{
      color: $white;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    background-color: $white;
    width: 11px;
    height: 11px;
    border-radius: 1px;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 6px;
      height: 6px;
      border-radius: 1px;
      background-color: $blue;
      transform: translate(-50%, -50%) scale(0);
      transition: all ease 0.4s;
    }
  }
}
