@import '../../styles';

.input {
  border-radius: 10px;
  font-size: 16px;
  line-height: 47px;
  font-family: $font-family-parisine-bold;
  box-shadow: none;
  border: 1px solid transparent;
  color: rgba($blue, 0.6);
  padding: 0 18px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: rgba($blue, 0.6);
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: rgba($blue, 0.6);
  }

  &.error {
    border-color: $pink;
  }
}

.form-group {
  max-width: 300px;
  margin: 0 auto 10px;
}

.input-advice {
  color: $white;
  font-size: 10px;
  text-align: left;
  margin-top: 5px;
  display: flex;
  align-items: center;
  line-height: 1.1;
  &-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}
