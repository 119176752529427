@import '../../styles';

.logo {
  margin-bottom: 20px;
}

.home-page {
  .container-color, .App{
    min-height: calc(var(--vh, 1vh) * 100);
    height: auto !important;
  }
  #root, App, .container-color, .container, .home {
    //height: auto !important;
    //min-height: unset !important;
  }

  .container-color {
    background-color: $blue;
    height: auto;
    overflow-y: scroll;
    //max-height: 100vh;
    //max-height: calc(var(--vh, 1vh) * 100);
    @media (max-width: $media-query-desktop) {
      overflow-y: scroll;
      height: auto;
      padding-top: 20px;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
    }
    @media (max-width: $media-query-desktop) {
      padding-top: 0;
    }

    .container {
      background-color: $blue;
      @media (max-width: $media-query-desktop) {
        height: auto;
      }

      @media (min-width: $media-query-desktop) {
        min-height: calc(100vh - 60px);
      }
      @media (max-width: $media-query-desktop) {
        padding: 15px;
        height: calc(var(--vh, 1vh) * 100);

      }
      @media (max-width: $media-query-mobile) {
        height: auto;
      }
      @media (max-width: $media-query-mobile-xxs) {
        height: auto;
      }
    }
  }

  &.with-pad {
    .container-color {
      height: auto;
      overflow-y: scroll;
      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100);
      min-height: 100%;
    }

    @media (max-width: $media-query-desktop) {
      .container {
        height: calc(100vh - 30px);
        height: calc((var(--vh, 1vh) * 100) - 30px);
      }
    }
  }
}


.home {
  background-color: $blue;
  height: 100%;
  font-family: $font-family-parisine-bold;
  display: flex;
  align-items: center;

  &.page-wrapper {
    padding-top: 0;
  }

  .header-text {
    font-size: 34px;
    line-height: 43px;
    position: relative;

    p {
      z-index: 2;
      position: relative;
      margin: 0;

      span {
        display: block;
      }
    }

    .uppercase {
      text-transform: uppercase;
      font-size: 48px;
    }

    .white-text {
      color: $white;
      transform: rotate(11deg);
    }

    .blue-text {
      color: $light-blue;
      font-size: 42px;
      transform: rotate(5deg);
    }

    .pink-text {
      color: $pink;
      transform: rotate(-3deg);
    }

    .green-text {
      color: $light-green;
      transform: rotate(11deg);
    }

    .orange-text {
      color: $orange;
      font-size: 48px;
      transform: rotate(-8deg);
      margin: 28px 0;

      &.name {
        margin: 0;
      }
    }

    .round {
      border-radius: 50%;
      width: 34px;
      height: 34px;
      position: absolute;

      &.bg-yellow {
        background-color: $yellow;
        left: 50%;
        top: 62%;
      }

      &.bg-violet {
        background-color: $violet;
        right: 30%;
        top: 41%;
      }

      &.little {
        width: 19px;
        height: 19px;
      }
    }

    .icon {
      opacity: 0.2;
      position: absolute;

      &.tram {
        transform: rotate(9deg);
        right: 30%;
        top: 4%;
      }

      &.metro {
        scale: 2.5;
        right: 5%;
        bottom: 0;
        transform: rotate(11deg);
      }

      &.rer {
        opacity: 0.4;
        scale: 1.5;
        left: 20%;
        bottom: -50px;
        transform: rotate(-36deg);
      }
    }
  }

  .form-container {
    max-width: 375px;
    margin: auto;
    @media (max-width: $media-query-desktop) {
      max-width: unset;
      width: 100%;
    }

    .form {
      margin-top: 20px;
      z-index: 1;
      position: relative;
      .form-group{
       .info-message{
         text-align: left;
         font-size: 12px;
         color: white;
       }
      }
    }
  }
}
