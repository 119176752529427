@import '../../styles';

.button{
  margin-top: 10px;
  background-color: $white;
  color: $blue;
  font-size: 20px;
  line-height: 47px;
  padding: 2px 36px;
  border-radius: 10px;
  font-family: $font-family-parisine-bold;
  border: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: all ease 0.5s;
  text-decoration: none;
  display: inline-block;
  &:hover{
    background-color: $blue;
    border-color: $white;
    color: $white;
  }
  &:disabled{
    opacity: 0.5;
    pointer-events: none;
  }
}
