@import '../../styles';

.bg {
  position: absolute;
  background-color: $blue;
  opacity: 1;
  inset: 0;
  pointer-events: none;
  transition: opacity .5s ease-in-out;
  z-index: 210;
}

.end {

  .App{
    @media (max-width: $media-query-desktop) and (orientation: landscape) {
      min-height: 600px;
      max-height: unset;
      .container{
        max-height: unset;
      }
      .end-step{
        height: auto;
      }
    }
  }
  .bg {
    opacity: 0;
  }

  .logo{
    margin-bottom: 70px;
  }

  #confetti-holder{
    width: 100%;
  }

  .container-color{
    background: $blue;
  }

  .container {
    background: $blue;
    overflow: hidden;
    padding: 0;
    @media (max-width: $media-query-desktop) {
      max-height: calc(var(--vh, 1vh) * 100);
    }
  }

  .end-step {
    height: 100%;
    color: $white;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: $media-query-desktop) {
      width: 90%;
    }
    span {
      display: block;
    }

    h1 {
      font-family: $font-family-parisine-bold;
      font-size: 36px;
      line-height: 42px;
      margin: 0;
    }

    .text {
      font-size: 23px;
      line-height: 36px;
    }
  }
}
