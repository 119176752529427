@import '../../styles';

.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  @media (max-width: $media-query-mobile-xxs) {
    padding-bottom: 0;
  }

  .container-bubble {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    @media (max-width: $media-query-tablet) {
      font-size: 10px;
    }

    p {
      margin: 0 0 5px;
    }
  }
}
