@import '../styles';

.text-wrapper {
  position: absolute;
  inset: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


.transition-overlay {
  .circle {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: #0E44AB;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%) translateY(50%) scale(0);
    z-index: 100;
    @media (max-width: $media-query-desktop) {
      position: fixed;
    }
    &.front {
      z-index: 101;
    }

    &.end {
      //transform: translateY(50%) translateX(-50%) scale(0);
      //z-index: 107;
      background: transparent;
    }
  }

  .text {
    transform: scale(0);
    color: white;
    visibility: hidden;
    opacity: 0;
    z-index: 104;
    text-align: center;
    max-width: 230px;

    h3 {
      margin: 0;
    }
  }

  .gif-img {
    position: absolute;
    inset: 0;
    pointer-events: none;
    z-index: 103;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      visibility: hidden;
      opacity: 0;
    }
  }
}
